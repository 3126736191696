@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.me {
    background-color: $couleur-base-blanc;
    border-bottom: 3px solid $couleur-accent-regular;
    padding: 0.5rem 0.5rem 0 0.5rem;
    position: relative;
    text-align: center;
    text-decoration: none;

    .titleZone{
        height: 93px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .titleName {
            @include titleH4;
            color: $couleur-text-regular;
            font-size: $font-size-small;
            margin: 0;
        }

        .titleCard {
            @include titleH3;
            font-size: $font-size-normal;
            margin: 0;
            padding: 0.3rem;
            text-align: center;
        }
    }

    .tag {
        background-color: $couleur-primaire-dark;
        color: $couleur-base-blanc;
        display: inline-block;
        font-weight: normal;
        left: 5%;
        padding: 3px 1rem;
        position: absolute;
        text-transform: capitalize;
        top: 0;
        z-index: 2;
    }

    .imgZone {
        margin: -0.5rem;
        margin-bottom: 0;
        overflow: hidden;
        padding-bottom: 100%;
        position: relative;
        width: calc(100% + 1rem);

        .bg {
            background-size: contain;
            filter: blur(3px);
            height: 110%;
            left: -5%;
            opacity: 0.5;
            position: absolute;
            top: -5%;
            width: 110%;
            z-index: 1;
        }

        .imgLink{
            display: block;
            filter: blur(0);
            max-width: 100%;
            position: absolute;
            top: 21.875%;
            width: 100%;
            z-index: 2;

            img{
                display: block;
                height: auto;
                margin: 0;
                width: 100%;
            }
        }

        .nbrRecettes {
            background-color: $couleur-base-blanc;
            bottom: 0;
            display: inline-block;
            font-weight: normal;
            left: 50%;
            margin: 0;
            margin-left: calc(-50px - 1rem);
            padding: 3px 1rem;
            position: absolute;
            width: 100px;
            z-index: 2;
        }
    }

    /* Variants */
    &.collection {
        padding: 0;

        .imgZone {
            padding: 0;
            width: 100%;
            margin: 0;

            .bg {
                display: none;
            }

            .img {
                object-fit: cover !important;
                width: 100%;
                aspect-ratio: 1 / 1;
            }
        }
    }
}
