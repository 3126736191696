@import '../../styles/variables.scss';
.etoilesContainer {
    display: inline-block;
    text-align: left;

    @media print {
        display: none;
    }

    span {
        color: $couleur-neutre-dark;
        text-align: left;
        margin-right: 0.15rem;
        &.active {
            color: $couleur-primaire-regular;
        }
        &:last-child {
            margin-right: 0;
        }
    }

    &.prototype {
        span {
            &.active {
                color: $couleur-base-noir;
            }
        }
    }

    @media #{$mq-large-and-over} {
        span {
            margin-right: 0.3rem;
        }
    }
}
