.me {
    margin: 0 0 3.5rem 0;

    .tempImage {
        max-width: 100%;
        width: 100%;
    }

    .title {
        margin: 1rem 0 0 0;
        text-align: center;
        width: 100%;
    }
}
