@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.me {
    background-color: $couleur-neutre-regular;
    position: relative;

    .blockLink {
        display: block;
        position: relative;
        height: 100%;

        .tag {
            left: 10px;
        }
    }

    figure {
        margin: 0;
        overflow: hidden;
        padding: 0;
        position: relative;
        width: 100%;
        z-index: 1;
    }

    .img {
        display: block;
        width: 100%;
    }

    .bg {
        background-color: $couleur-base-blanc;
        bottom: 10px;
        box-sizing: border-box;
        margin: 0 10px;
        padding: 0 0.5rem;
        position: absolute;
        text-align: center;
        width: calc(100% - 20px);
        z-index: 3;

        @media #{$mq-medium-and-over} {
            margin: 0 1rem;
            width: calc(100% - 20px - 1rem);
        }
    }

    .category {
        @include titleH5;
        font-weight: 500;
        margin-bottom: 0;
        padding: 0.5rem;
    }

    a {
        color: $couleur-base-noir;
        display: block;

        .titleCart {
            margin-bottom: 10px;
            margin-top: 0;
        }
    }

    .auteurContainer {
        align-items: center;
        display: inline-flex;
        justify-content: center;
        width: 100%;
    }

    .auteur {
        border-radius: 20px;
        margin: 0.5rem;
        max-width: 40px;
        vertical-align: middle;
        width: 40px;
    }

    /* Variants */
    &.horizontal {
        figure {
            overflow: hidden;
            padding-bottom: 0;
            width: 47%;
        }

        .bg {
            align-items: center;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            height: calc(100% - 20px);
            justify-content: center;
            margin: 0;
            position: absolute;
            right: 10px;
            text-align: center;
            width: 55%;

            @media #{$mq-medium-and-over} {
                margin: 0;
            }

            .titleCart {
                @include titleH4;
                @include text-truncate(3);
                margin-bottom: 0;

                @media #{$mq-large-and-over} {
                    @include titleH3;
                    @include text-truncate(4);
                }
            }

            .category {
                @include titleH5;
                margin-bottom: 10px;
                margin-top: 0;
                padding: 0;
            }
        }
    }

    &.collection {
        .figure {
            height: 100%;
            margin: 0 !important;

            .link {
                display: contents;
            }

            .img {
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
