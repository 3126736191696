@import '../../../styles/variables.scss';

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
@keyframes fade {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
}

.me {
    background-color: $couleur-base-blanc;
    border-bottom: 3px solid $couleur-accent-regular;
    display: flex;
    flex-direction: column;
    padding: 0;
    position: relative;
    text-decoration: none;

    .linkImage {
        .figure {
            margin: 0 !important;
            padding: 0;
            overflow: hidden;
            position: relative;
            width: 100%;
            z-index: 1;

            .img {
                aspect-ratio: 1 / 1;
                display: block;
                width: 100%;
            }
        }
    }

    .section {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        padding-bottom: 0.2rem;

        .titreCart {
            display: block;
            font-family: 'futura-pt', sans-serif;
            font-size: $font-size-l;
            margin: 0.5rem 0;
            padding: 0.3rem 0.8rem;
            text-align: center;
        }

        .info {
            align-items: center;
            display: flex;
            justify-content: space-between;
            width: 100%;

            .evaluation:only-child,
            .time:only-child {
                margin: 0 auto;
            }

            .evaluation {
                display: block;
                font-size: $font-size-small;
                padding: 0 0.5rem;
            }

            .time {
                display: block;
                font-weight: 600;
                line-height: 1rem;
                padding: 0 0.5rem;
            }
        }
    }
}
