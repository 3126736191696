@import '../../../styles/variables.scss';

.me {
    background-color: #fff;
    position: relative;
    text-decoration: none;
    border-bottom: 3px solid $couleur-accent-regular;
    text-align: center;
    .imgZone {
        position: relative;
        width: 100%;
        img {
            width: 100%;
            max-width: 100%;
        }
    }
    .logo {
        width: auto;
        max-width: 100%;
        max-height: 80px;
    }
}
