@import '../../styles/variables';

.me {
    background-color: $couleur-primaire-dark;
    color: $couleur-base-blanc;
    display: inline-block;
    font-weight: normal;
    height: 26px;
    left: 5%;
    line-height: 26px;
    padding: 0 10px;
    position: absolute;
    text-transform: capitalize;
    top: 0;
    white-space: nowrap;
    z-index: 2;
}
