@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.me {
    background-color: white;
    isolation: isolate;
    position: relative;
    text-align: center;

    a:hover {
        text-decoration: none;
    }

    .titleZone {
        display: flex;
        flex-direction: column;
        height: 145px;
        justify-content: center;
        padding: 0.5rem 1rem;
        text-align: center;

        a {
            display: block;
            text-align: center;
        }

        img {
            height: 100%;
            margin: 0 auto;
            max-height: 100px;
            width: auto;
        }

        .titleName {
            @include titleH4;
            @include text-truncate(2);

            font-size: $font-size-normal;
            line-height: 1.1;
            margin: 0;
            color: $couleur-text-regular;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .imgLink {
        display: block;
        position: relative;
        text-align: center;

        span {
            bottom: 10px;
            position: absolute;
            right: 0;
        }

        img {
            display: block;
            width: 100%;
        }
    }
}